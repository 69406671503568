<template>
  <div class="auth-body ribbon-container">
    <div class="auth-el">
      <auth-card>
        <sign-up-form></sign-up-form>
      </auth-card>
    </div>
  </div>
</template>

<script>
import AuthCard from "@/components/auth/components/AuthCard";
import SignUpForm from "@/components/auth/components/SignUpForm";

export default {
  name: "ConfirmSignUp",
  components: {
    authCard: AuthCard,
    signUpForm: SignUpForm,
  },
}
</script>

<style scoped>
</style>
