<template>
  <v-card
    tile
    width="559px"
    class="card-border"
    style="min-height: 700px"
  >
    <v-container style="padding-top: 25px;">
      <img src="../../../assets/image/bKash-logo.svg" @click="$router.push({name: 'landing'})" class="bkash-icon" style="cursor: pointer;" >
      <v-col>
        <div style="margin-bottom: 10px;">
          <span class="header-text-bold">
            Welcome Back to
          </span>
        </div>
        <div style="margin-bottom: 50px;">
          <span class="header-text">
            bKash Business Dashboard
          </span>
        </div>
        <slot/>
      </v-col>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "AuthCard"
}
</script>

<style scoped>
.auth-head-layout {
  display: flex;
  flex-direction: column
}

.header-text-bold {
  font-size: 42px;
  margin-top: 50px;
  font-stretch: normal;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: normal;
  text-align: center;
  color: #707070;
}

.header-text {
  font-family: Roboto !important;
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  line-height: 12px;
  letter-spacing: normal;
  text-align: center;
  color: #95989a;
}

.card-border {
  border: 1px solid #95989a;
  box-shadow: 0 3px 3px -2px rgba(0,0,0,.2),0 3px 4px 0 rgba(0,0,0,.14),0 1px 8px 0 rgba(0,0,0,.12)!important;
}
@media only screen and (max-width: 425px) {
  .card-border {
    border: none;
    box-shadow: none !important;
  }

  .header-text-bold {
    font-size: 31px;
  }

  .header-text {
    font-size: 22px;
  }
}
</style>
