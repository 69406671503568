<template>
  <div>
    <v-form class="form-style" v-model="isValid" ref="form">
      <v-text-field
          class="inputNumber input-field-size"
          style="margin-top: -15px;"
          label="Mobile Number"
          placeholder="Mobile Number"
          v-model="phoneNumber"
          :rules="numberRules"
          type="number"
          outlined>
      </v-text-field>
      <v-text-field
          class="input-field-size"
          v-model="currentPass"
          :rules="passwordRules"
          label="Temporary Password"
          placeholder="Temporary Password"
          type="Password"
          outlined>
      </v-text-field>
      <v-text-field
          class="input-field-size"
          v-model="newPass"
          @keyup="matchPasswords"
          :rules="passwordPolicy"
          label="New Password"
          placeholder="New Password"
          type="Password"
          outlined>
      </v-text-field>
      <v-text-field
          class="input-field-size"
          v-model="retypedPass"
          :rules="confirmPasswordRule"
          type="Password"
          label="Confirm New Password"
          placeholder="Confirm New Password"
          outlined>
      </v-text-field>

      <v-btn
          color="primary"
          :disabled="!isValid"
          class="button-size"
          required
          style="font-size: 22px"
          @click="updatePassword"
      >
        Update
      </v-btn>
    </v-form>
  </div>
</template>

<script>

import {PASSWORD_POLICY} from "cpp-js-util/CppUtil";
import encryptionLib from "cpp-js-util/encryption";

export default {
  name: "SignUpForm",
  components: {},
  data() {
    return {
      phoneNumber: this.$store.getters.getUserPhoneNumber,
      currentPass: '',
      retypedPass: '',
      newPass: '',
      isValid: true,
      numberRules: [
        v => !!v || 'Number is required',
        v => (v && v.length === 11) || 'Number must have 11 digit'
      ],
      passwordRules: [
        v => !!v || 'Password is required',
      ],
      passwordPolicy: [
        v => !!v || 'Password is required',
        v => PASSWORD_POLICY.test(v) ||
            'Password must have 8+ characters and at least one uppercase, lowercase, digit & special character',
      ],
      confirmPasswordRule: [
        v => !!v || 'Password is required',
        v => PASSWORD_POLICY.test(v) ||
            'Password must have 8+ characters and at least one uppercase, lowercase, digit & special character',
        v => (v === this.newPass) || 'Password must match',
      ],
      autofilled: false,
    }
  },
  methods: {
    async updatePassword() {
      this.$feedback.showLoading();
      let body = {
        phoneNumber: this.phoneNumber,
        previousPassword: this.currentPass,
        password: this.newPass,
      }

      let {nonce, publicKey} = await encryptionLib.getNonceAndRsaPublicKey(this.$cppClient, this.$feedback);
      body.previousPassword = encryptionLib.encryptWithRsaPublicKeyAndNonce(publicKey, nonce, body.previousPassword);
      body.password = encryptionLib.encryptWithRsaPublicKeyAndNonce(publicKey, nonce, body.password);

      this.$cppClient.post('/change-temporary-password', body)
          // eslint-disable-next-line no-unused-vars
          .then(async (res) => {
            let payload = {phoneNumber: this.phoneNumber, password: this.newPass};
            let {nonce, publicKey} = await encryptionLib.getNonceAndRsaPublicKey(this.$cppClient, this.$feedback);
            payload.password = encryptionLib.encryptWithRsaPublicKeyAndNonce(publicKey, nonce, payload.password);

            this.$cppClient.post('/login', payload)
                .then(res => {
                  this.$feedback.hideLoading();
                  this.$store.commit('setCredentials', res.data);
                  this.$gtag.event("First time logged in", {
                    'event_category': "Sign In Page"
                  });
                  this.$router.push({name: 'dashboard', params: {firstTimeLogin: true}});
                })
                .catch(({response}) => {
                  this.$feedback.showFailed(response);
                })
          })
          .catch(({response}) => {
            this.$feedback.showFailed(response);
          })
    },
    matchPasswords() {
      if (this.retypedPass) {
        this.$refs.form.validate()
      }
    }
  }
}
</script>

<style scoped>
.input-field-size {
  margin-bottom: 42px;
  width: 329px;
  height: 51px;
  display: inline-block;
}

.button-size {
  height: 39px;
  width: 329px;
}

.form-style {
  margin-bottom: 25px;
}

@media only screen and (max-width: 425px) {
  .input-field-size {
    margin-bottom: 52px;
    width: 100%
  }

  .button-size {
    width: 100%;
    display: inline-block;
  }

  .form-style {
    display: inline-block;
    width: 261px;
  }
}

@media screen and (max-width: 768px) {
  .input-field-size {
    width: 329px !important;
  }
}
</style>
